<template>
  <div>
    <ListDriversWorkCities></ListDriversWorkCities>
  </div>
</template>

<script> 

  
import ListDriversWorkCities from '@core/components/driver-work-cities/ListDriversWorkCities.vue'

export default {
  components: {
    ListDriversWorkCities,
    // AddGoogleMap,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
